<template>

    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label>客户 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 placeholder="请输入客户名称"
                                 @change="name_change"></a-input>
                        <a-button type="primary" @click="search">查询</a-button>
                        <a-button @click="Syndata">同步</a-button>
                    </a-col>
                </a-row>
                <!--:customRow="handleClickRow"-->
                <a-table bordered :columns="columns"
                         rowKey="UserID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         class="yj-table-img">
                    <span slot="imgs" slot-scope="text">
                        <img class="imgPreview" :src="text" alt="" />
                    </span>

                </a-table>
            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import axios from 'axios'
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    axios.defaults.timeout = 500000;
    export default {
        name: "Operations_List",
        data() {
            return {
                columns: [
                    {
                        title: "头像",
                        dataIndex: "UHeadImg",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: "昵称",
                        dataIndex: "NickName",
                        width: 200,
                        ellipsis: true,

                    },
                    {
                        title: "性别",
                        width: 150,
                        customRender: (text, item) => {
                            var Gender = '未知';
                            switch (item.Gender) {
                                case 0:
                                    Gender = '未知';
                                    break;
                                case 1:
                                    Gender = '男';
                                    break;
                                case 2:
                                    Gender = '女';
                                    break;
                            }

                            return Gender;
                        }
                    },
                    {
                        title: "加入时间",
                        width: 250,
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "更新时间",
                        width: 250,
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.RefreshTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "是否关注",
                        width: 250,
                        customRender: (text, item) => {
                            if (item.IsSub == -1)
                                return "未知";
                            else if (item.IsSub == 1)
                                return "已关注";
                            else if (item.IsSub == 0)
                                return "未关注";
                            //var temp = new Date(util.TimeStamptoDateTime(item.RefreshTime, 'yyyy-MM-dd hh:mm:ss'));

                            //return util.formatDate3(temp);
                        }
                    }
                ],
                category: "",
                tableData: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                customRowOption: {
                    on: {
                        click: this.RowClick
                    }
                }
            };
        },
        methods: {
            Syndata: function () {
                var self = this;
                this.$confirm({
                    title: '提示',
                    content: '同步时间较长，是否确定同步',
                    okText: "确定",
                    cancelText: '取消',
                    onOk() {
                        var op = {
                            url: "/OperationsModule/Operations/Syn",
                            data: {
                            },
                            OnSuccess: function (data) {
                                console.log(data)
                                self.Tipsconfirm('同步完成，同步人数' + data.data);
                            },

                            OnError: function () {
                            }
                        };
                        http.Post(op);
                    },
                    onCancel() { },
                });
            },
            Tipsconfirm(e) {
                this.$info({
                    title: '提示',
                    content: e,
                    okText: "确定"
                });
            },
            handleClickRow(record) {
                var self = this
                return {
                    on: {
                        click: () => {
                            //console.log(record.UserID)
                            self.$router.push({
                                name: "OperationsDetail", query: {
                                    ID: record.UserID
                                }
                            })
                        }
                    }
                }
            },
            customRow: function () {
                return this.customRowOption;
            },
            RowClick: function (e) {
                console.log(e);
            },
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            radioChange: function (item) {
                var self = this;
                self.category = item.target.value;
                //self.getTableData();
                self.search();
            },
            search: function () {
                this.pagination.current = 1;
                this.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/UserPoolItem",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
        },
        mounted() {

            this.getTableData();
        },
        beforeMount() {
            var self = this;
            var Category = this.$route.query.Category;
            if (!util.isBlank(Category)) {
                self.category = Category.toString();
            } else {
                self.category = "-1"
            }
        },
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>